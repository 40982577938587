import React from "react"
import Autosuggest from "react-autosuggest"
import styled from "styled-components"
import theme from "assets/styles/theme"

const StyledWrapper = styled.div`
    position: relative;
    width: 100%;
    background: none transparent !important;
    color: white;
    text-align: center;
    margin-bottom: 0.5rem;
    padding: ${({ padding }) => (padding ? padding : "0 24px")};

    &:focus {
      outline: none;
      
      &:before {
            content: none;
        }
    }
    
    .react-autosuggest__container {
    text-align: center;
    margin-bottom: 1rem;
    }
    
    .react-autosuggest__input {
        position: relative;
        background-color: transparent !important;
        width: 100%;
        border: 1px solid ${theme.color.inputBorderColor};
        border-radius: ${theme.radius.input};
        padding: ${theme.padding.input};
    
        background: none;
        color: white;
        text-align: center;
        margin-bottom: 0.5rem;
    }
    
    .react-autosuggest__input--focused {
        outline: none;
    }
    
    .react-autosuggest__suggestions-container {
        display: none;
    }
    
    .react-autosuggest__suggestions-container--open {
        display: block;
        width: 100%;
        border: 1px solid;
        border-radius: 25px;
        background: white;
        color: black;
    }
    
    .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    .react-autosuggest__suggestion {
        cursor: pointer;
        padding: 10px 20px;
        font-weight: bolder;
    }
`

let options = []

const getSuggestions = value => {
    return options
}

const getSuggestionValue = suggestion => suggestion.name

const renderSuggestion = suggestion => (
    <div key={suggestion.id}>
        {suggestion.name}
    </div>
)

class AutocompleteInput extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            value: props.value,
            id: props.id,
            suggestions: [],
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value,
                id: this.props.id,
            })
        }

        if (prevProps.options !== this.props.options) {
            options = this.props.options
        }
    }

    onChange = (event, { newValue, newId }) => {
        this.setState({
            value: newValue,
            id: newId ? newId : 0,
        }, ()=>{
            this.props.onChange(this.state.value, this.state.id)
        })
    }

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value),
        })
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        })
    }

    onSuggestionSelected = (event, { suggestion }) => {
        this.setState({
            value: suggestion.name,
            id: suggestion.id,
        })
        this.props.onChange(suggestion.name, suggestion.id)
    }

    render() {
        const { value, id, suggestions } = this.state
        const autosuggestId = this.props.autosuggestId;

        const inputProps = {
            placeholder: this.props.meta && this.props.meta.required ? this.props.placeholder + ' *' : this.props.placeholder,
            value,
            id,
            onChange: this.onChange,
        }

        return (
            <StyledWrapper>
                <Autosuggest
                    id={autosuggestId}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    onSuggestionSelected={this.onSuggestionSelected}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                >
                </Autosuggest>
            </StyledWrapper>
        )
    }
}

export default AutocompleteInput
import React from "react"
import styled from "styled-components"
import theme from "assets/styles/theme"

const CheckboxInputWrapper = styled.div`
    padding: ${({ padding }) => (padding ? padding : "0 24px")};
    text-align: center;
    margin-bottom: 1rem;
`

const StyledLabel = styled.label`
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    margin-bottom: 1rem;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 30px;
        width: 30px;
        background-color: transparent;
        border-radius: ${theme.radius.input};
        border: 1px solid ${theme.color.inputBorderColor};

        &:after {
            content: "";
            position: absolute;
            display: none;
            
            left: 9px;
            top: 3px;
            width: 10px;
            height: 15px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }

    input:checked ~ .checkmark:after {
        display: block;
    }
`

const StyledContent = styled.div`
    font-size: 1.5rem;
    padding-left: 40px;
    text-align: left;
    min-height: 30px;
`

const CheckboxInput = ({handler, touched, hasError, meta}) => {
    return (
        <CheckboxInputWrapper padding={meta.padding}>
            {meta.style === "bordered" &&
            <div>
                <StyledLabel>
                    <StyledContent>{meta.content}</StyledContent>
                    <input type="checkbox" {...handler()}/>
                    <span className="checkmark"/>
                </StyledLabel>
            </div>
            }

            <span>
                {touched
                && hasError("required")
                && `Pole ${meta.label} jest wymagane.`}
            </span>
        </CheckboxInputWrapper>
    )
}

export default CheckboxInput
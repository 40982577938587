import React from "react"
import MainTemplate from "templates/Main/MainTemplate";
import CreateProfileScreen from "components/Pages/CreateProfile";

const CreateProfilePage = (props) => (
    <MainTemplate {...props}>
        <CreateProfileScreen {...props}/>
    </MainTemplate>
);

export default CreateProfilePage;